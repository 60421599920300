@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    html {
        scroll-behavior: smooth;
    }

    :root{
        --neutral-white: 255, 255, 255;
        --neutral-gradient: linear-gradient(92deg, #733EC7 54.26%, #CA62DB 72.98%);
        --neutral-black-gradient: linear-gradient(180deg, rgba(2, 2, 2, 0.00) -3.03%, #000 58.71%);
        --neutral-gray: 153, 153, 153;
        --neutral-black: 63, 63, 63;
        --disabled: 138, 138, 138;
        --border-radius: 8px;
        --primary: 115, 62, 199;
        --secondary: 46, 23, 82;
        --neutral-purple: 115, 62, 199;

        --neutral-red: 239, 68, 68;
        --neutral-green: 34, 197, 94;
        --neutral-blue: 14, 165, 233;
        --neutral-yellow: 234, 178, 8;
        --neutral-orange: 249, 117, 22;

        --font-family: 'SF Pro Display', sans-serif;
        --text-xs: 0.75rem;
        --text-sm: 0.875rem;
        --text-base: 1.3rem;
        --text-lg: 1.4rem;
        --text-xl: 1.5rem;
        --text-2xl: 2rem;
        --text-3xl: 3rem;

        --text-lh-base: 23px;
        --text-lh-xl: 32px;
        --text-lh-2xl: 48px;
        --text-lh-3xl: 64px;
    }
    
    html[data-theme=dark]{
        --gradient-bg: linear-gradient(90deg, #110424 56.64%, rgba(17, 4, 36, 0.00) 207.58%), #08040C;
        --primary-text: 255, 255, 255;
        --prose-text: 220, 220, 220;
        --montonic: 27, 14, 46;
    }
    
    html[data-theme=light]{
        --gradient-bg: linear-gradient(0deg, #FFF 0%, #FFF 100%), #CACACA;
        --primary-text: 21, 10, 38;
        --prose-text: 0, 0, 0;
        --montonic: 255, 255, 255;
    }
}

@layer utilities {
    .text-gradient {
        background: var(--neutral-gradient);
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .bg-gradient {
        background: var(--gradient-bg);
    }

    .border-gradient{
        position: relative;
        transition: all 0.2s ease-in-out;
    }
    .border-gradient::before {
        content: "";
        position: absolute;
        z-index: var(--z-index, -1);
        inset: 0px;
        pointer-events: none;
        padding: 2px;
        border-radius: var(--border-gradient-radii, inherit);
        background: var(--neutral-gradient);
        -webkit-mask: linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px) content-box content-box, linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }

    .prose {
        font-family: var(--font-family);
        line-height: var(--text-lh-base);
    }

    .prose :is(h1, h2, h3, h4, h5, h6) {
        font-weight: 700;
    }
    .prose h1 {
        font-size: var(--text-2xl);
        line-height: var(--text-lh-2xl);
    }
    .prose h2 {
        font-size: var(--text-xl);
        line-height: var(--text-lh-xl);
    }
    .prose h3 { font-size: var(--text-lg); }
    .prose h4 { font-size: var(--text-base); }
    .prose h5 { font-size: var(--text-sm); }
    .prose h6 { font-size: var(--text-xs); }

    .prose :is(p, ol, ul, li, em, b, a, code, pre, span) { font-size: var(--text-base); }

    .prose :is(h1, h2, h3, h4, h5, h6, p, ol, ul, li, em, strong, b, a, code, pre, span) { 
        margin-bottom: 0.5rem; 
    }

    .prose :is(h1, h2, h3, h4, h5, h6), .prose p>strong {
        color: rgb(var(--primary-text)) !important;
    }

    .prose :is(p) {
        color: rgb(var(--prose-text)) !important;
    }

    .prose ul {
        list-style-type: disc;
        padding-left: 1.5rem;
    }

    .prose ol {
        list-style-type: decimal;
        padding-left: 1.5rem;
    }

    .prose pre, .prose kbd {
        font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
        font-size: var(--text-base);
        line-height: var(--text-lh-base);
        padding: 0.75rem;
        overflow-x: auto;
        background: rgba(var(--neutral-purple), 30%);
        border-radius: var(--border-radius);
    }

    .prose a, .prose u {
        color: rgb(var(--neutral-blue));
        text-decoration: underline;
    }

    .prose a:visited, .prose u:visited {
        color: rgb(var(--neutral-purple));
    }

}

* {
    box-sizing: border-box;
    line-height: 1.5;
    margin: 0;
    padding: 0;
}
